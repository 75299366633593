import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

import Layout, { Section } from "../layout"
import SEO from "../seo"
import Button from "../Button"
import Center from "../Center"

const doneTick = require("../../images/check.svg")

export default function ThankYouForContactingUs(props: any) {
  const intl = useIntl()

  const email = props?.location?.state?.email

  return (
    <Layout>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "title" })} />

      <Section>
        <Center>
          <img src={doneTick} />
          <h2>
            <FormattedMessage id="thankYouForContactingUs.title" />
          </h2>
          <p>
            <FormattedMessage
              id="thankYouForContactingUs.description"
              values={{
                email: email ? `(${email})` : "",
              }}
            />
          </p>
          <Button to="/">
            {intl.formatMessage({ id: "thankYouForContactingUs.CTA" })}
          </Button>
        </Center>
      </Section>
    </Layout>
  )
}
